'use client'

import { ThemeProvider, useTheme } from 'next-themes';
import { useEffect } from 'react';

export function Providers({ children }: any) {
  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme('dark');  // Set the theme to 'dark' by default
  }, [setTheme]);  
  return <ThemeProvider attribute='class' defaultTheme='dark'>{children}</ThemeProvider>
}